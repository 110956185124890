import React, { memo, useState, Fragment, useContext, useEffect } from 'react';
import type { FC } from 'react';
import { useIntl, defineMessages } from 'react-intl-next';
import isEqual from 'lodash/isEqual';

import Button from '@atlaskit/button/standard-button';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import {
	TEMPLATE_PREVIEW_EXPERIENCE,
	ExperienceTimeout,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { FullPageLoadingScreen } from '@confluence/full-page-loading-screen';
import {
	Attribution,
	withErrorBoundary,
	isNotFoundError,
	isUnauthorizedError,
} from '@confluence/error-boundary';
import type { ExtensionHandlerProps } from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';
import { useCreateContentFromTemplate } from '@confluence/create-content-from-template';
import type { Template } from '@confluence/create-content-from-template';
import type { FlagsStateContainer } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import { PopupPreview } from '@confluence/template-preview';
import { useSessionData } from '@confluence/session-data';
import { getMonitoringClient } from '@confluence/monitoring';
import {
	getExperienceName,
	getMacroAttributesFromADFNode,
	MacroExperienceSuccess,
} from '@confluence/macro-tracker';
import { markErrorAsHandled } from '@confluence/graphql';
import type { TemplateUtilsTemplate } from '@confluence/template-utils';
import {
	getTemplateIdAttributesForAnalytics,
	getUserTimezoneDate,
} from '@confluence/template-utils';

import { getSpaceName, getTemplateInfo } from './dataFunctions';
import { useCreatePageExperienceTracker } from './useCreatePageExperienceTracker';
import { useCreateFromTemplateAnalyticsEvent } from './useCreateFromTemplateAnalyticsEvent';

export interface CreateFromTemplateInterface {
	cloudId: string;
	userId: string;
	macroDefaultProps: ExtensionHandlerProps;
	isPreviewMode?: boolean;
	contentType?: string;
	ssrButtonLabel?: string;
}

type CreateFromTemplateInjectedProps = {
	flags: FlagsStateContainer;
};

type PreviewTemplate = {
	name: string | null;
	contentBlueprintId: string | null;
	templateId: string | null;
	blueprintModuleCompleteKey: string | null;
	itemModuleCompleteKey: string | null;
	iconURL: string | null;
	darkModeIconURL?: string | null;
	styleClass: string | null;
};

const i18n = defineMessages({
	createFromTemplateDefaultLabelText: {
		id: 'create-from-template.inline-extension.create-from-template',
		defaultMessage: 'Create from Template',
		description:
			'Button text that lets the user create a brand new templated page when they click on the button',
	},
	createFromTemplateError: {
		id: 'create-from-template.inline-extension.create-from-template.error',
		defaultMessage:
			'You may not have permission to add pages in the destination space. Check permissions, or edit the space key in the Create From Template macro.',
		description:
			'Error message that the user sees in the error flag when something goes wrong when trying to create content',
	},
	unavailableTemplateError: {
		id: 'create-from-template.inline-extension.create-from-template.unavailable-template.error',
		defaultMessage:
			'The original template has been deleted. Select a new template for the Create From Template macro.',
		description:
			'Error message that the user sees in the error flag when the configured template is no longer available',
	},
	notFoundError: {
		id: 'create-from-template.inline-extension.create-from-template.not-found-graphql-error.error',
		description:
			'error message when parent page id of page user is trying to create does not exist',
		defaultMessage: 'Refresh the page to try again.',
	},
	notFoundErrorTitle: {
		id: 'create-from-template.inline-extension.create-from-template.not-found-graphql-error.error.title',
		description:
			'title for error message when parent page id of page user is trying to create does not exist',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: "We couldn't create a page for you",
	},
});

const CreateFromTemplateContainer: FC<
	CreateFromTemplateInterface & CreateFromTemplateInjectedProps
> = ({ macroDefaultProps, flags, isPreviewMode, contentType, ssrButtonLabel }) => {
	const { node, mode, contentId, extensionKey } = macroDefaultProps;
	const [showLoading, setShowLoading] = useState(false);
	const [macroParams, setMacroParams] = useState<{ [key: string]: string }>({});
	const [currentTemplate, setCurrentTemplate] = useState<Template>();

	const {
		buttonLabel: userConfiguredButtonLabel,
		createButtonLabel: macroButtonLabel,
		contentBlueprintId,
		templateId,
		spaceKey,
		blueprintModuleCompleteKey,
		title: pageTitle,
	} = macroParams;

	const intl = useIntl();
	const buttonLabel =
		ssrButtonLabel ||
		userConfiguredButtonLabel ||
		macroButtonLabel ||
		intl.formatMessage(i18n.createFromTemplateDefaultLabelText);
	const createFromTemplate = useCreateContentFromTemplate();
	const experienceTracker = useContext(ExperienceTrackerContext);
	const createCFTMacroAnalyticsEvent = useCreateFromTemplateAnalyticsEvent();
	const {
		startCreatePageExperience,
		stopCreatePageExperienceOnError,
		succeedCreatePageExperience,
	} = useCreatePageExperienceTracker();
	const isSameSpace = spaceKey === macroDefaultProps?.spaceKey;
	const isPageType = contentType === 'page';
	const name = getExperienceName(mode, node);
	const attributes = getMacroAttributesFromADFNode(node);
	const { userId, isLicensed, timeZone } = useSessionData();
	const isWYSIWYGCreateFromTemplateMacroEnabled = FeatureGates.checkGate(
		'confluence_frontend_page_experiences_cft_wysiwig',
	);

	const updateCustomTitle = async () => {
		if (pageTitle) {
			let title: string = pageTitle;

			if (title.includes('@spaceName')) {
				const spaceName: string = await getSpaceName(spaceKey);
				title = title.replace(/@spaceName/g, `${spaceName}`);
			}

			if (title.includes('@currentDate')) {
				const currentDate = getUserTimezoneDate({
					userId,
					isLicensed,
					timeZone,
					dateFormat: 'yyyy-MM-dd',
				});
				title = title.replace(/@currentDate/g, `${currentDate}`);
			}

			title = title.replace(/@spaceKey/g, `${spaceKey}`);

			return title;
		}
		return '';
	};

	useEffect(() => {
		const unwrapMacroParameters = () => {
			const macroParams = Object.keys(node.parameters.macroParams);
			const unwrappedParams = macroParams.reduce((acc, parameter) => {
				if (!acc[parameter]) {
					acc[parameter] = node.parameters.macroParams[parameter]?.value;
				}
				return acc;
			}, {});

			setMacroParams(unwrappedParams);
		};

		unwrapMacroParameters();
	}, [node]);

	const template: Template = {
		contentBlueprintId: contentBlueprintId || null,
		templateId: templateId || null,
		blueprintModuleCompleteKey: blueprintModuleCompleteKey || null,
	};

	const getSelectedTemplateInfo = async () => {
		const fetchedTemplate = await getTemplateInfo(
			templateId || contentBlueprintId || blueprintModuleCompleteKey,
		);

		return fetchedTemplate
			? {
					...template,
					contentBlueprintId: fetchedTemplate.contentBlueprintId,
					hasWizard: fetchedTemplate.hasWizard,
					itemModuleCompleteKey: fetchedTemplate.itemModuleCompleteKey,
					iconURL: fetchedTemplate.iconURL,
					darkModeIconURL: fetchedTemplate.darkModeIconURL,
					blueprintModuleCompleteKey:
						blueprintModuleCompleteKey || fetchedTemplate?.blueprintModuleCompleteKey,
					name: fetchedTemplate.name,
				}
			: null;
	};

	const handleMouseOver = async (showPreview, e: React.MouseEvent | React.FocusEvent) => {
		const target = e?.currentTarget as HTMLElement;
		e.persist();

		try {
			const selectedTemplate = (await getSelectedTemplateInfo()) as Template;
			setCurrentTemplate(selectedTemplate);

			experienceTracker.start({
				name: TEMPLATE_PREVIEW_EXPERIENCE,
				timeout: ExperienceTimeout.TEMPLATE_PREVIEW,
				attributes: {
					source: 'createFromTemplateMacro',
					...getTemplateIdAttributesForAnalytics(selectedTemplate as TemplateUtilsTemplate),
				},
			});

			showPreview(selectedTemplate, target?.getBoundingClientRect(), 0);
		} catch (error) {
			if (isUnauthorizedError(error) || isNotFoundError(error)) {
				markErrorAsHandled(error);
			} else {
				handleTemplatePreviewError(error);
			}
		}
	};

	const createContentFromTemplate = async () => {
		setShowLoading(true);

		startCreatePageExperience();
		try {
			createCFTMacroAnalyticsEvent({
				type: 'sendUIEvent',
				source: 'createFromTemplateMacro',
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'useTemplate',
				attributes: {
					...getTemplateIdAttributesForAnalytics(template as TemplateUtilsTemplate),
				},
			});

			const title = await updateCustomTitle();
			const selectedTemplate = (await getSelectedTemplateInfo()) as Template;
			if (!selectedTemplate) {
				succeedCreatePageExperience();
				return await flags.showErrorFlag({
					title: intl.formatMessage(i18n.unavailableTemplateError),
				});
			}

			await createFromTemplate(selectedTemplate, spaceKey, {
				title,
				parentPageId: isPageType && isSameSpace ? contentId : '',
				openContentInNewTab: isWYSIWYGCreateFromTemplateMacroEnabled,
			});
		} catch (error) {
			if (isNotFoundError(error)) {
				markErrorAsHandled(error);
				void flags.showErrorFlag({
					title: intl.formatMessage(i18n.notFoundErrorTitle),
					description: intl.formatMessage(i18n.notFoundError),
				});
			} else if (isUnauthorizedError(error)) {
				markErrorAsHandled(error);
				void flags.showErrorFlag({
					title: intl.formatMessage(i18n.createFromTemplateError),
				});
			} else {
				handleCreateFromTemplateError(error);
			}
		} finally {
			setShowLoading(false);
		}
	};

	const handleCreateFromTemplateError = (error) => {
		stopCreatePageExperienceOnError(error);
		getMonitoringClient().submitError(error, {
			attribution: Attribution.TAILORED_EXPERIENCES,
		});

		createCFTMacroAnalyticsEvent({
			type: 'sendTrackEvent',
			action: 'error',
			actionSubject: 'createFromTemplateMacro',
			actionSubjectId: 'contentCreateWithTemplateError',
			attributes: {
				errorMessage: error.message,
			},
		});

		markErrorAsHandled(error);
		void flags.showErrorFlag({
			title: intl.formatMessage(i18n.createFromTemplateError),
		});
	};

	const handleTemplatePreviewError = (error) => {
		experienceTracker.fail({
			name: TEMPLATE_PREVIEW_EXPERIENCE,
			error,
			attributes: {
				source: 'createFromTemplateMacro',
			},
		});
		getMonitoringClient().submitError(error, {
			attribution: Attribution.TAILORED_EXPERIENCES,
		});

		createCFTMacroAnalyticsEvent({
			type: 'sendTrackEvent',
			action: 'error',
			actionSubject: 'createFromTemplateMacro',
			actionSubjectId: 'templatePreview',
			attributes: {
				errorMessage: error.message,
			},
		});

		markErrorAsHandled(error);
	};

	return (
		<Fragment>
			<PopupPreview
				spaceKey={spaceKey || macroDefaultProps?.spaceKey}
				templates={[currentTemplate] as PreviewTemplate[]}
				hoverSource="createFromTemplateMacro"
				hasDynamicPositioning
				isEditorView={false}
			>
				{({ showPreview, hidePreview }) => (
					<Button
						isDisabled={isPreviewMode}
						onClick={createContentFromTemplate}
						onMouseEnter={(e) => handleMouseOver(showPreview, e)}
						onFocus={(e) => handleMouseOver(showPreview, e)}
						onMouseLeave={hidePreview}
						onBlur={hidePreview}
					>
						{buttonLabel}
					</Button>
				)}
			</PopupPreview>
			{showLoading && <FullPageLoadingScreen />}
			<MacroExperienceSuccess
				name={name}
				contentId={contentId}
				extensionKey={extensionKey}
				mode={mode}
				attributes={attributes}
			/>
		</Fragment>
	);
};

export const CreateFromTemplate = memo(
	withErrorBoundary({
		attribution: Attribution.TAILORED_EXPERIENCES,
	})(withFlags(CreateFromTemplateContainer)),
	isEqual,
);
